const data = {
  'Selected': 'Selecionado: {0} de {1}',
  'Uploading files': 'Fazendo o upload {0}% de {1}',
  'File size error': '{0} é muito grande, por favor faça upload de arquivos menores que {1}',
  'Upload failed': '{0} falhou ao fazer o upload',
  'Per page': '{0} Por página',
  'Folder': 'Diretório',
  'Login failed, please try again': 'Login falhou, por favor tente novamente',
  'Already logged in': 'Já está logado',
  'Please enter username and password': 'Por favor entre com o nome de usuário e a senha',
  'Not Found': 'Não encontrado',
  'Not Allowed': 'Não autorizado',
  'Please log in': 'Por favor faça o login',
  'Unknown error': 'Erro desconhecido',
  'Add files': 'Adicionar arquivos',
  'New': 'Novo',
  'New name': 'Novo nome',
  'Username': 'Nome de usuário',
  'Password': 'Senha',
  'Login': 'Entrar',
  'Logout': 'Sair',
  'Profile': 'Perfil',
  'No pagination': 'Sem paginação',
  'Time': 'Data',
  'Name': 'Nome',
  'Size': 'Tamanho',
  'Home': 'Página inicial',
  'Copy': 'Copiar',
  'Move': 'Mover',
  'Rename': 'Renomear',
  'Required': 'Por favor preencha este campo',
  'Zip': 'Comprimir',
  'Batch Download': 'Download em lote',
  'Unzip': 'Descomprimir',
  'Delete': 'Deletar',
  'Download': 'Download',
  'Copy link': 'Copiar link',
  'Done': 'Finalizado',
  'File': 'Arquivo',
  'Drop files to upload': 'Arraste arquivos para fazer o upload',
  'Close': 'Fechar',
  'Select Folder': 'Selecionar diretório',
  'Users': 'Usuários',
  'Files': 'Arquivos',
  'Role': 'Perfil',
  'Cancel': 'Cancelar',
  'Paused': 'Pausado',
  'Confirm': 'Confirmar',
  'Create': 'Criar',
  'User': 'Usuário',
  'Admin': 'Administrador',
  'Save': 'Salvar',
  'Read': 'Ler',
  'Write': 'Escrever',
  'Upload': 'Upload',
  'Permissions': 'Permissões',
  'Homedir': 'Página inicial',
  'Leave blank for no change': 'Deixe em branco para não fazer nenhuma alteração',
  'Are you sure you want to do this?': 'Tem certeza que deseja fazer isto?',
  'Are you sure you want to allow access to everyone?': 'Tem certeza que deseja permitir o acesso a todos?',
  'Are you sure you want to stop all uploads?': 'Tem certeza que deseja parar todos os uploads?',
  'Something went wrong': 'Algo deu errado',
  'Invalid directory': 'Diretório inválido',
  'This field is required': 'Este campo é obrigatório',
  'Username already taken': 'O nome de usuário já existe',
  'User not found': 'Usuário não encontrado',
  'Old password': 'Senha atual',
  'New password': 'Nova senha',
  'Wrong password': 'Senha inválida',
  'Updated': 'Atualizado',
  'Deleted': 'Excluído',
  'Your file is ready': 'Seu arquivo está pronto',
  'View': 'Visualizar',
  'Search': 'Procurar',
  'Download permission': 'Download',
  'Guest': 'Convidado',
  'Show hidden': 'Mostrar ocultos',
}

export default data

