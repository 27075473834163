const data = {
  'Selected': '已选择: {1} 个文件中的 {0} 个',
  'Uploading files': '已上传 {1} 中的 {0}%',
  'File size error': '{0} 尺寸过大, 您最大只可上传 {1}',
  'Upload failed': '{0} 上传失败',
  'Per page': '每页 {0} 个',
  'Folder': '文件夹',
  'Login failed, please try again': '登录失败, 请重试',
  'Already logged in': '已登录。',
  'Please enter username and password': '请输入用户名和密码。',
  'Not Found': '未找到',
  'Not Allowed': '不允许',
  'Please log in': '请登录',
  'Unknown error': '未知错误',
  'Add files': '上传文件',
  'New': '新建',
  'New name': '新名称',
  'Username': '用户名',
  'Password': '密码',
  'Login': '登录',
  'Logout': '退出',
  'Profile': '更改信息',
  'No pagination': '不分页',
  'Time': '时间',
  'Name': '名称',
  'Size': '大小',
  'Home': '主页',
  'Copy': '复制',
  'Move': '移动',
  'Rename': '重命名',
  'Required': '请填写此字段',
  'Zip': '压缩',
  'Batch Download': '批量下载',
  'Unzip': '解压缩',
  'Delete': '删除',
  'Download': '下载',
  'Copy link': '复制链接',
  'Done': '完成',
  'File': '文件',
  'Drop files to upload': '拖放文件即可上传',
  'Close': '关闭',
  'Select Folder': '选择文件夹',
  'Users': '用户',
  'Files': '文件',
  'Role': '角色',
  'Cancel': '取消',
  'Paused': '暂停',
  'Confirm': '确认',
  'Create': '创建',
  'User': '用户',
  'Admin': '管理员',
  'Save': '保存',
  'Read': '读取',
  'Write': '写入',
  'Upload': '上传',
  'Permissions': '权限',
  'Homedir': '根目录',
  'Leave blank for no change': '留空表示不更改',
  'Are you sure you want to do this?': '你确定要干这事?',
  'Are you sure you want to allow access to everyone?': '你确定要让任何人随意访问?',
  'Are you sure you want to stop all uploads?': '你确定要停止所有上传任务?',
  'Something went wrong': '有啥坏了',
  'Invalid directory': '目录无效',
  'This field is required': '必须填写这个字段',
  'Username already taken': '用户名已被注册',
  'User not found': '未找到用户',
  'Old password': '旧密码',
  'New password': '新密码',
  'Wrong password': '密码错误',
  'Updated': '已更新',
  'Deleted': '已删除',
  'Your file is ready': '您的文件已备妥',
  'View': '查看',
  'Search': '搜索',
  'Download permission': '下载',
  'Guest': '游客',
  'Show hidden': '显示隐藏',
}

export default data

