const data = {
  'Selected': 'Vybrané: {0} z {1}',
  'Uploading files': 'Nahrávám {0}% z {1}',
  'File size error': '{0} je příliš velký, nahrávejte soubory menší jak {1}',
  'Upload failed': '{0} se nepodařilo nahrát',
  'Per page': '{0} na stránku',
  'Folder': 'Adresář',
  'Login failed, please try again': 'Přihlášení neúspěšné, zkuste to znova',
  'Already logged in': 'Už jste přihlášený.',
  'Please enter username and password': 'Zadejte přihlašovací jméno a heslo.',
  'Not Found': 'Nenalezeno',
  'Not Allowed': 'Nepovolené',
  'Please log in': 'Přihlaste se',
  'Unknown error': 'Neznámá chyba',
  'Add files': 'Nahrát soubory',
  'New': 'Nový',
  'New name': 'Nové jméno',
  'Username': 'Přihlašovací jméno',
  'Password': 'Heslo',
  'Login': 'Přihlásit se',
  'Logout': 'Odhlásit se',
  'Profile': 'Profil',
  'No pagination': 'Bez stránkování',
  'Time': 'Čas',
  'Name': 'Jméno',
  'Size': 'Velikost',
  'Home': 'Hlavní adresář',
  'Copy': 'Kopírovat',
  'Move': 'Přesunout',
  'Rename': 'Přejmenovat',
  'Required': 'Vyplňte toto pole',
  'Zip': 'Archivovat do zip',
  'Batch Download': 'Hromadné stahování',
  'Unzip': 'Rozbalit zip archív',
  'Delete': 'Smazat',
  'Download': 'Stáhnout',
  'Copy link': 'Zkopírovat odkaz',
  'Done': 'Hotovo',
  'File': 'Soubor',
  'Drop files to upload': 'Pro nahrání přesuňte soubory sem',
  'Close': 'Zavřít',
  'Select Folder': 'Vyberte adresář',
  'Users': 'Uživatelé',
  'Files': 'Soubory',
  'Role': 'Typ účtu',
  'Cancel': 'Zrušit',
  'Paused': 'Pozastavené',
  'Confirm': 'Potvrdit',
  'Create': 'Vytvořit',
  'User': 'Uživatel',
  'Admin': 'Admin',
  'Save': 'Uložit',
  'Read': 'Čtení',
  'Write': 'Zapisování',
  'Upload': 'Nahrávání',
  'Permissions': 'Oprávnění',
  'Homedir': 'Hlavní adresář',
  'Leave blank for no change': 'Pokud nechcete změnit, nechejte prázdné',
  'Are you sure you want to do this?': 'Skutečně to chcete udělat?',
  'Are you sure you want to allow access to everyone?': 'Skutečně chcete povolit přístup bez hesla?',
  'Are you sure you want to stop all uploads?': 'Skutečně chcete zastavit všechna nahrávání?',
  'Something went wrong': 'Něco se pokazilo',
  'Invalid directory': 'Neplatný adresář',
  'This field is required': 'Toto pole je povinné',
  'Username already taken': 'Toto přihlašovací jméno se už používá',
  'User not found': 'Uživatel se nenašel',
  'Old password': 'Staré heslo',
  'New password': 'Nové heslo',
  'Wrong password': 'Špatné heslo',
  'Updated': 'Aktualizované',
  'Deleted': 'Smazané',
  'Your file is ready': 'Váš soubor je připravený',
  'View': 'Zobrazit',
  'Search': 'Vyhledávání',
  'Download permission': 'Stahování',
  'Guest': 'Host',
  'Show hidden': 'Zobrazit skryté',
}

export default data
