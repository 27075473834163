const data = {
  'Selected': 'Seleccionados: {0} de {1}',
  'Uploading files': 'Subindo arquivo {0}% de {1}',
  'File size error': '{0} O arquivo é demasiado grande. Por favor, cargue arquivos de menos de {1}',
  'Upload failed': '{0} Erro ao subir',
  'Per page': '{0} Por páxina',
  'Folder': 'Cartafol',
  'Login failed, please try again': 'Houbo un erro no acceso, proba de novo.',
  'Already logged in': 'Xa iniciaches sesión.',
  'Please enter username and password': 'Por favor, insire usuario e contrasinal.',
  'Not Found': 'Non se atopou',
  'Not Allowed': 'Non permitido',
  'Please log in': 'Por favor, inicie sesión',
  'Unknown error': 'Erro descoñecido',
  'Add files': 'Engadir Arquivos',
  'New': 'Novo',
  'New name': 'Novo nome',
  'Username': 'Usuario',
  'Password': 'Contrasinal',
  'Login': 'Iniciar sesión',
  'Logout': 'Saír',
  'Profile': 'Perfil',
  'No pagination': 'Sen paxinación',
  'Time': 'Hora',
  'Name': 'Nome',
  'Size': 'Tamaño',
  'Home': 'Inicio',
  'Copy': 'Copiar',
  'Move': 'Mover',
  'Rename': 'Renomear',
  'Required': 'Por favor, encha este campo',
  'Zip': 'Arquivo comprimido',
  'Batch Download': 'Descarga en lotes',
  'Unzip': 'Descomprimir',
  'Delete': 'Eliminar',
  'Download': 'Baixar',
  'Copy link': 'Copiar ligazón',
  'Done': 'Feito',
  'File': 'Arquivo',
  'Drop files to upload': 'Arrastra e solta os arquivos para carregar',
  'Close': 'Pechar',
  'Select Folder': 'Escoller Cartafol',
  'Users': 'Usuarios',
  'Files': 'Arquivos',
  'Role': 'Privilexio',
  'Cancel': 'Cancelar',
  'Paused': 'Pausado',
  'Confirm': 'Confirmar',
  'Create': 'Crear',
  'User': 'Usuario',
  'Admin': 'Administrador',
  'Save': 'Gardar',
  'Read': 'Ler',
  'Write': 'Escribir',
  'Upload': 'Carregar',
  'Permissions': 'Permisos',
  'Homedir': 'Cartafol de Inicio',
  'Leave blank for no change': 'Deixa en branco para non facer cambios',
  'Are you sure you want to do this?': 'Estás seguro de que queres facer isto?',
  'Are you sure you want to allow access to everyone?': 'Estás seguro de que queres darlle acceso a calquera?',
  'Are you sure you want to stop all uploads?': 'Estás seguro de que queres deter todas as cargas?',
  'Something went wrong': 'Algo saíu mal',
  'Invalid directory': 'Dirección non válida',
  'This field is required': 'Este campo é obrigatorio',
  'Username already taken': 'O usuario xa existe',
  'User not found': 'Non se atopou o usuario',
  'Old password': 'Contrasinal antiga',
  'New password': 'Nova contrasinal',
  'Wrong password': 'Contrasinal errada',
  'Updated': 'Actualizado',
  'Deleted': 'Eliminado',
  'Your file is ready': 'O teu arquivo está listo',
  'View': 'Ver',
  'Show hidden': 'Amosar oculto',
}

export default data
