const data = {
  'Selected': 'Izabrano: {0} od {1}',
  'Uploading files': 'Slanje {0}% od {1}',
  'File size error': '{0} fajl je preveliki, molim pošaljite fajl manji od {1}',
  'Upload failed': '{0} greška kod slanja',
  'Per page': '{0} Po strani',
  'Folder': 'Folder',
  'Login failed, please try again': 'Neuspešna prijava, probajte ponovo',
  'Already logged in': 'Već prijavljen.',
  'Please enter username and password': 'Unesite korisničko ime i lozinku.',
  'Not Found': 'Nije pronađen',
  'Not Allowed': 'Nije dozvoljeno',
  'Please log in': 'Molim prijavite se',
  'Unknown error': 'Nepoznata greška',
  'Add files': 'Dodaj fajlove',
  'New': 'Novi',
  'New name': 'Novo ime',
  'Username': 'Korisničko ime',
  'Password': 'Lozinka',
  'Login': 'Prijavi se',
  'Logout': 'Odjavi se',
  'Profile': 'Profil',
  'No pagination': 'Bez listanja po strani',
  'Time': 'Vreme',
  'Name': 'Ime',
  'Size': 'Veličina',
  'Home': 'Početna',
  'Copy': 'Kopiraj',
  'Move': 'Premesti',
  'Rename': 'Promeni ime',
  'Required': 'Ovo polje je obavezno',
  'Zip': 'Zip',
  'Batch Download': 'Grupno preuzimanje',
  'Unzip': 'Unzip',
  'Delete': 'Obriši',
  'Download': 'Preuzmi',
  'Copy link': 'Kopiraj link',
  'Done': 'Gotovo',
  'File': 'Fajl',
  'Drop files to upload': 'Spusti fajlove za slanje',
  'Close': 'Zatvori',
  'Select Folder': 'Izaberi folder',
  'Users': 'Korisnici',
  'Files': 'Fajlovi',
  'Role': 'Prava',
  'Cancel': 'Otkaži',
  'Paused': 'Pauzirano',
  'Confirm': 'Potvrdi',
  'Create': 'Kreiraj',
  'User': 'Korisnik',
  'Admin': 'Administrator',
  'Save': 'Sačuvaj',
  'Read': 'Čitanje',
  'Write': 'Upis',
  'Upload': 'Slanje',
  'Permissions': 'Prava pristupa',
  'Homedir': 'Početni folder',
  'Leave blank for no change': 'Ostavi prazno da ne promeniš',
  'Are you sure you want to do this?': 'Da li ste sigurni?',
  'Are you sure you want to allow access to everyone?': 'Da li ste sigurni da želite da dozvolite pristup svima?',
  'Are you sure you want to stop all uploads?': 'Da li ste sigurni da želite da prekinete sva slanja?',
  'Something went wrong': 'Dogodila se nepoznata greška',
  'Invalid directory': 'Pogrešan folder',
  'This field is required': 'Ovo polje je obavezno',
  'Username already taken': 'Korisničko ime već postoji',
  'User not found': 'Korisnik nije pronađen',
  'Old password': 'Stara lozinka',
  'New password': 'Nova lozinka',
  'Wrong password': 'Pogrešna lozinka',
  'Updated': 'Izmenjeno',
  'Deleted': 'Obrisano',
  'Your file is ready': 'Vaš fajl je spreman',
  'View': 'View',
  'Search': 'Search',
  'Download permission': 'Preuzimanje',
  'Guest': 'Gost',
  'Show hidden': 'Prikaži skriveno',
}

export default data

