const data = {
  'Selected': 'Selezionati: {0} di {1}',
  'Uploading files': 'Caricamento {0}% di {1}',
  'File size error': '{0} File troppo grande. Dimensione massima consentita {1}',
  'Upload failed': '{0} Caricamento fallito',
  'Per page': '{0} per pagina',
  'Folder': 'Cartella',
  'Login failed, please try again': 'Username o password non corretti',
  'Already logged in': 'Sei già connesso',
  'Please enter username and password': 'Inserisci username e password',
  'Not Found': 'Nessun risultato',
  'Not Allowed': 'Non consentito',
  'Please log in': 'Per cortesia autenticati',
  'Unknown error': 'Errore sconosciuto',
  'Add files': 'Aggiungi files',
  'New': 'Nuovo',
  'New name': 'Nuovo nome',
  'Username': 'Username',
  'Password': 'Password',
  'Login': 'Entra',
  'Logout': 'Esci',
  'Profile': 'Cambia password',
  'No pagination': 'Uno per pagina',
  'Time': 'Data',
  'Name': 'Nome',
  'Size': 'Dimensione',
  'Home': 'Cartella principale',
  'Copy': 'Copia',
  'Move': 'Sposta',
  'Rename': 'Rinomina',
  'Required': 'Campo obbligatorio',
  'Zip': 'Comprimi',
  'Batch Download': 'Scarica batch',
  'Unzip': 'Estrai',
  'Delete': 'Elimina',
  'Download': 'Scarica',
  'Copy link': 'Copia collegamento',
  'Done': 'Completato',
  'File': 'File',
  'Drop files to upload': 'Trascina i files che vuoi caricare',
  'Close': 'Chiudi',
  'Select Folder': 'Seleziona cartella',
  'Users': 'Utenti',
  'Files': 'Files',
  'Role': 'Ruolo',
  'Cancel': 'Annulla',
  'Paused': 'Sospeso',
  'Confirm': 'Conferma',
  'Create': 'Crea',
  'User': 'Utente',
  'Admin': 'Amministratore',
  'Save': 'Salva',
  'Read': 'Lettura',
  'Write': 'Scrittura',
  'Upload': 'Caricamento',
  'Permissions': 'Permessi',
  'Homedir': 'Cartella principale',
  'Leave blank for no change': 'Lascia in bianco per non effettuare modifiche',
  'Are you sure you want to do this?': 'Sei sicuro di voler eliminare gli elementi selezionati?',
  'Are you sure you want to allow access to everyone?': 'Sei sicuro di voler consentire libero accesso a tutti?',
  'Are you sure you want to stop all uploads?': 'Vuoi sospendere tutti i caricamenti?',
  'Something went wrong': 'Qualcosa é andato storto',
  'Invalid directory': 'Cartella non corretta',
  'This field is required': 'Questo campo é obbligatorio',
  'Username already taken': 'Username giá esistente',
  'User not found': 'Utente non trovato',
  'Old password': 'Vecchia password',
  'New password': 'Nuova password',
  'Wrong password': 'Password errata',
  'Updated': 'Aggiornato',
  'Deleted': 'Eliminato',
  'Your file is ready': 'Il tuo file è disponibile',
  'View': 'Leggi',
  'Search': 'Cerca',
  'Download permission': 'Scarica',
  'Guest': 'Guest',
  'Show hidden': 'Mostra nascosto',
}

export default data

