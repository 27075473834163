const data = {
  'Selected': 'Selectionné : {0} sur {1}',
  'Uploading files': 'Upload {0}% sur {1}',
  'File size error': '{0} est trop volumineux, merci d\'uploader des fichiers inférieurs à {1}',
  'Upload failed': '{0} échec(s) d\'envoi',
  'Per page': '{0} par page',
  'Folder': 'Dossier',
  'Login failed, please try again': 'Identification échoué, veuillez réessayer...',
  'Already logged in': 'Vous êtes déjà connecté.',
  'Please enter username and password': 'Saisissez votre nom d\'utilisateur et votre mot de passe.',
  'Not Found': 'Introuvable',
  'Not Allowed': 'Non autorisé',
  'Please log in': 'Merci de vous connecter',
  'Unknown error': 'Erreur inconnue',
  'Add files': 'Ajout de fichier',
  'New': 'Nouveau',
  'New name': 'Nouveau nom',
  'Username': 'Nom d\'utilisateur',
  'Password': 'Mot de passe',
  'Login': 'Connexion',
  'Logout': 'Déconnexion',
  'Profile': 'Profil',
  'No pagination': 'Pas de pagination',
  'Time': 'Date',
  'Name': 'Nom',
  'Size': 'Taille',
  'Home': 'Accueil',
  'Copy': 'Copier',
  'Move': 'Déplacer',
  'Rename': 'Renommer',
  'Required': 'Merci de remplir ce champ',
  'Zip': 'Compresser',
  'Batch Download': 'Télécharger par lot',
  'Unzip': 'Décompresser',
  'Delete': 'Supprimer',
  'Download': 'Télécharger',
  'Copy link': 'Copier le lien',
  'Done': 'Fait',
  'File': 'Fichier',
  'Drop files to upload': 'Glisser votre fichier pour l\'uploader',
  'Close': 'Fermer',
  'Select Folder': 'Selectionner le dossier',
  'Users': 'Utilisateur',
  'Files': 'Fichiers',
  'Role': 'Rôle',
  'Cancel': 'Annuler',
  'Paused': 'En pause',
  'Confirm': 'Confirmer',
  'Create': 'Créer',
  'User': 'Utilisateur',
  'Admin': 'Administrateur',
  'Save': 'Enregistrer',
  'Read': 'Lire',
  'Write': 'Écrire',
  'Upload': 'Uploader',
  'Permissions': 'Permissions',
  'Homedir': 'Dossier principal',
  'Leave blank for no change': 'Laisser vide si pas de modification',
  'Are you sure you want to do this?': 'Êtes-vous sûr de vouloir faire ceci ?',
  'Are you sure you want to allow access to everyone?': 'Êtes-vous sûr de vouloir autoriser l\'accès à tout le monde ?',
  'Are you sure you want to stop all uploads?': 'Êtes-vous sûr de vouloir arrêter tous vos envois ?',
  'Something went wrong': 'Quelque chose a mal tourné',
  'Invalid directory': 'Dossier invalide',
  'This field is required': 'Ce champ est obligatoire',
  'Username already taken': 'Nom d\'utilisateur déjà utilisé',
  'User not found': 'Utilisateur introuvable',
  'Old password': 'Ancien mot de passe',
  'New password': 'Nouveau mot de passe',
  'Wrong password': 'Mot de passe incorrect',
  'Updated': 'Mis à jour',
  'Deleted': 'Supprimé',
  'Your file is ready': 'Votre fichier est prêt',
  'View': 'View',
  'Search': 'Search',
  'Download permission': 'Télécharger',
  'Guest': 'Guest',
  'Show hidden': 'Afficher masqué',
  'Large archive': 'Fichier compressé massif. Le fichier sera disponible bientôt. Veuillez rafraîchir le contenu de cette page dans quelques minutes...'
}

export default data

