const data = {
  'Selected': 'Vybrané: {0} z {1}',
  'Uploading files': 'Nahrávam {0}% z {1}',
  'File size error': '{0} je príliš veľký, nahrávajte súbory menšie ako {1}',
  'Upload failed': '{0} sa nepodarilo nahrať',
  'Per page': '{0} na stránku',
  'Folder': 'Adresár',
  'Login failed, please try again': 'Prihlásenie neúspešné, skúste to znova',
  'Already logged in': 'Už ste prihlásený.',
  'Please enter username and password': 'Zadajte prihlasovacie meno a heslo.',
  'Not Found': 'Nenájdené',
  'Not Allowed': 'Nepovolené',
  'Please log in': 'Prihláste sa',
  'Unknown error': 'Neznáma chyba',
  'Add files': 'Pridať súbory',
  'New': 'Nový',
  'New name': 'Nové meno',
  'Username': 'Prihlasovacie meno',
  'Password': 'Heslo',
  'Login': 'Prihlásiť sa',
  'Logout': 'Odhlásiť sa',
  'Profile': 'Profil',
  'No pagination': 'Bez stránkovania',
  'Time': 'Čas',
  'Name': 'Meno',
  'Size': 'Veľkosť',
  'Home': 'Hlavný adresár',
  'Copy': 'Kopírovať',
  'Move': 'Presunúť',
  'Rename': 'Premenovať',
  'Required': 'Vyplňte toto pole',
  'Zip': 'Archivovať do zip',
  'Batch Download': 'Hromadné sťahovanie',
  'Unzip': 'Rozbaliť zip archív',
  'Delete': 'Vymazať',
  'Download': 'Stiahnuť',
  'Copy link': 'Skopírovať odkaz',
  'Done': 'Hotovo',
  'File': 'Súbor',
  'Drop files to upload': 'Pre nahratie presuňte súbory sem',
  'Close': 'Zavrieť',
  'Select Folder': 'Vyberte adresár',
  'Users': 'Používatelia',
  'Files': 'Súbory',
  'Role': 'Typ účtu',
  'Cancel': 'Zrušiť',
  'Paused': 'Pozastavené',
  'Confirm': 'Potvrdiť',
  'Create': 'Vytvoriť',
  'User': 'Používateľ',
  'Admin': 'Admin',
  'Save': 'Uložiť',
  'Read': 'Čítanie',
  'Write': 'Zapisovanie',
  'Upload': 'Nahrávanie',
  'Permissions': 'Oprávnenia',
  'Homedir': 'Hlavný adresár',
  'Leave blank for no change': 'Ak nechcete zmeniť nechajte prázdne',
  'Are you sure you want to do this?': 'Naozaj to chcete urobiť?',
  'Are you sure you want to allow access to everyone?': 'Naozaj chcete povoliť prístup bez hesla?',
  'Are you sure you want to stop all uploads?': 'Naozaj chcete zastaviť všetky nahrávania?',
  'Something went wrong': 'Niečo sa pokazilo',
  'Invalid directory': 'Neplatný adresár',
  'This field is required': 'Toto pole je povinné',
  'Username already taken': 'Toto prihlasovacie meno sa už používa',
  'User not found': 'Používateľ sa nenašiel',
  'Old password': 'Staré heslo',
  'New password': 'Nové heslo',
  'Wrong password': 'Zlé heslo',
  'Updated': 'Aktualizované',
  'Deleted': 'Vymazané',
  'Your file is ready': 'Váš súbor je pripravený',
  'View': 'Zobraziť',
  'Search': 'Vyhľadávanie',
  'Download permission': 'Sťahovanie',
  'Guest': 'Hosť',
  'Show hidden': 'Zobraziť skryté',
}

export default data
