const data = {
  'Selected': 'Vald: {0} of {1}',
  'Uploading files': 'Laddar upp {0}% of {1}',
  'File size error': '{0} är för stor, max filstorlek är {1}',
  'Upload failed': '{0} uppladdning misslyckades',
  'Per page': '{0} Per sida',
  'Folder': 'Mapp',
  'Login failed, please try again': 'Inloggning misslyckades, försök igen.',
  'Already logged in': 'Redan inloggad.',
  'Please enter username and password': 'Ange användarnamn och lösenord.',
  'Not Found': 'Ej funnen',
  'Not Allowed': 'Ej tillåten',
  'Please log in': 'Var vanlig logga in.',
  'Unknown error': 'Okänt fel',
  'Add files': 'Lägg till filer',
  'New': 'Ny',
  'New name': 'Nytt namn',
  'Username': 'Användarnamn',
  'Password': 'Lösenord',
  'Login': 'Logga in',
  'Logout': 'Logga ut',
  'Profile': 'Profil',
  'No pagination': 'Sidhantering',
  'Time': 'Tid',
  'Name': 'Namn',
  'Size': 'Storlek',
  'Home': 'Hem',
  'Copy': 'Kopiera',
  'Move': 'Flytta',
  'Rename': 'Byt namn',
  'Required': 'Vänligen fyll i detta fält',
  'Zip': 'Zip',
  'Batch Download': 'Batch nedladdning',
  'Unzip': 'Unzip',
  'Delete': 'Borttag',
  'Download': 'Ladda ned',
  'Copy link': 'Kopiera länk',
  'Done': 'Klar',
  'File': 'Fil',
  'Drop files to upload': 'Släpp filer för uppladdning',
  'Close': 'Stäng',
  'Select Folder': 'Välj mapp',
  'Users': 'Användare',
  'Files': 'Filer',
  'Role': 'Roll',
  'Cancel': 'Avbryt',
  'Paused': 'Pausad',
  'Confirm': 'Godkänn',
  'Create': 'Skapa',
  'User': 'Användare',
  'Admin': 'Admin',
  'Save': 'Spara',
  'Read': 'Läs',
  'Write': 'Skriv',
  'Upload': 'Ladda upp',
  'Permissions': 'Behörigheter',
  'Homedir': 'Hem mapp',
  'Leave blank for no change': 'Lämna blankt för ingen ändring',
  'Are you sure you want to do this?': 'Är du säker på att du vill göra detta?',
  'Are you sure you want to allow access to everyone?': 'Vill du verkligen ge access till alla?',
  'Are you sure you want to stop all uploads?': 'Vill du stoppa alla uppladdningar?',
  'Something went wrong': 'Något gick fel',
  'Invalid directory': 'Ogiltig mapp',
  'This field is required': 'Detta fält krävs',
  'Username already taken': 'Användarnamnet finns redan',
  'User not found': 'Användaren hittas inte',
  'Old password': 'Gammalt lösenord',
  'New password': 'Nytt lösenord',
  'Wrong password': 'fel lösenord',
  'Updated': 'Uppdaterad',
  'Deleted': 'Borttagen',
  'Your file is ready': 'Din fil är klar',
  'View': 'Visa',
  'Search': 'Sök',
  'Download permission': 'Ladda ned',
  'Guest': 'Gäst',
  'Show hidden': 'Visa dold',
}

export default data

