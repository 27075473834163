const data = {
  'Selected': 'Kijelölés: {0} Kijelölve {1}',
  'Uploading files': 'Feltöltés {0}% Feltöltve {1}',
  'File size error': '{0} Túl nagy fájl {1}',
  'Upload failed': '{0} Sikertelen feltöltés',
  'Per page': '{0} Oldalanként',
  'Folder': 'Mappa',
  'Login failed, please try again': 'Sikertelen belépés, próbálja újra',
  'Already logged in': 'Bejelentkezve.',
  'Please enter username and password': 'Kérjük, adja meg a felhasználónevét és jelszavát.',
  'Not Found': 'Nem található',
  'Not Allowed': 'Nem megengedett',
  'Please log in': 'Kérjük jelentkezzen be',
  'Unknown error': 'Ismeretlen hiba',
  'Add files': 'Fájl hozzáadása',
  'New': 'Új',
  'New name': 'Új felhasználó',
  'Username': 'Felhasználónév',
  'Password': 'Jelszó',
  'Login': 'Belépés',
  'Logout': 'Kilépés',
  'Profile': 'Profil',
  'No pagination': 'Nincs lap',
  'Time': 'Idő',
  'Name': 'Név',
  'Size': 'Méret',
  'Home': 'Főkönyvtár',
  'Copy': 'Másol',
  'Move': 'Áthelyez',
  'Rename': 'Átnevez',
  'Required': 'Kérem töltse ki ezt a mezőt',
  'Zip': 'Becsomagol',
  'Batch Download': 'Kötegelt letöltés',
  'Unzip': 'Kicsomagolás',
  'Delete': 'Törlés',
  'Download': 'Letöltés',
  'Copy link': 'Link másolása',
  'Done': 'Kész',
  'File': 'Fájl',
  'Drop files to upload': 'Dobja el a feltöltendő fájlokat',
  'Close': 'Bezár',
  'Select Folder': 'Mappa kijelölése',
  'Users': 'Felhasználók',
  'Files': 'Fájlok',
  'Role': 'Szerep',
  'Cancel': 'Mégse',
  'Paused': 'Szünetel',
  'Confirm': 'Megerősít',
  'Create': 'Létrehoz',
  'User': 'Felhasználó',
  'Admin': 'Adminisztrátor',
  'Save': 'Mentés',
  'Read': 'Olvasás',
  'Write': 'Írás',
  'Upload': 'Feltöltés',
  'Permissions': 'Engedélyek',
  'Homedir': 'Fő mappa',
  'Leave blank for no change': 'Hagyja üresen változtatás nélkül',
  'Are you sure you want to do this?': 'Biztosan meg akarja változtatni?',
  'Are you sure you want to allow access to everyone?': 'Biztos, hogy mindenkinek engedélyezi a hozzáférést?',
  'Are you sure you want to stop all uploads?': 'Biztosan leállítja az összes feltöltést?',
  'Something went wrong': 'Valami elromlott',
  'Invalid directory': 'Érvénytelen mappa',
  'This field is required': 'Mező kitöltése kötelező',
  'Username already taken': 'A felhasználónév már foglalt',
  'User not found': 'Felhasználó nem található',
  'Old password': 'Régi jelszó',
  'New password': 'Új jelszó',
  'Wrong password': 'Rossz jelszó',
  'Updated': 'Feltöltés',
  'Deleted': 'Törlés',
  'Your file is ready': 'Your file is ready',
  'View': 'Nézet',
  'Search': 'Keresés',
  'Download permission': 'Letöltés engedélyezés',
  'Guest': 'Vendég',
  'Show hidden': 'Rejtett megjelenítése',
}

export default data

