const data = {
  'Selected': 'Pasirinkta: {0} iš {1}',
  'Uploading files': 'Įkeliama {0}% iš {1}',
  'File size error': '{0} yra per didelis, prašome įkelti mažesnius failus nei {1}',
  'Upload failed': '{0} nepavyko įkelti',
  'Per page': '{0} puslapyje',
  'Folder': 'Aplankas',
  'Login failed, please try again': 'Nepavyko prisijungti, bandykite dar kartą',
  'Already logged in': 'Jau esate prisijungęs.',
  'Please enter username and password': 'Prašome įvesti prisijungimo vardą ir slaptažodį.',
  'Not Found': 'Nerasta',
  'Not Allowed': 'Neleidžiama',
  'Please log in': 'Prašome prisijungti',
  'Unknown error': 'Nežinoma klaida',
  'Add files': 'Įkelti failus',
  'New': 'Naujas',
  'New name': 'Naujas pavadinimas',
  'Username': 'Prisijungimo vardas',
  'Password': 'Slaptažodis',
  'Login': 'Prisijungti',
  'Logout': 'Atsijungti',
  'Profile': 'Profilis',
  'No pagination': 'Nepuslapiuoti',
  'Time': 'Laikas',
  'Name': 'Pavadinimas',
  'Size': 'Dydis',
  'Home': 'Pradžia',
  'Copy': 'Kopijuoti',
  'Move': 'Perkelti',
  'Rename': 'Pervadinti',
  'Required': 'Prašome užpildyti šį lauką',
  'Zip': 'Zip',
  'Batch Download': 'Atsiųsti paketą',
  'Unzip': 'Išpakuoti',
  'Delete': 'Pašalinti',
  'Download': 'Atsiųsti',
  'Copy link': 'Kopijuoti nuorodą',
  'Done': 'Atlikta',
  'File': 'Failas',
  'Drop files to upload': 'Nutempti failus įkėlimui',
  'Close': 'Užverti',
  'Select Folder': 'Pasirinkite aplanką',
  'Users': 'Vartotojai',
  'Files': 'Failai',
  'Role': 'Vaidmuo',
  'Cancel': 'Atšaukti',
  'Paused': 'Pristabdytas',
  'Confirm': 'Patvirtinti',
  'Create': 'Sukurti',
  'User': 'Vartotojas',
  'Admin': 'Admin',
  'Save': 'Išsaugoti',
  'Read': 'Nuskaityti',
  'Write': 'Įrašyti',
  'Upload': 'Įkelti',
  'Permissions': 'Leidimai',
  'Homedir': 'Pradžios aplankas',
  'Leave blank for no change': 'Palikite tuščią, jei nenorite nieko keisti',
  'Are you sure you want to do this?': 'Ar Jūs įsitikinęs, kad norite tai atlikti?',
  'Are you sure you want to allow access to everyone?': 'Ar Jūs įsitikinęs, kad norite atverti prieigą prie failų bet kam?',
  'Are you sure you want to stop all uploads?': 'Ar Jūs įsitikinęs, kad norite sustabdyti visus įkėlimus?',
  'Something went wrong': 'Kažkas negerai',
  'Invalid directory': 'Neteisingas aplankas',
  'This field is required': 'Šį lauką privalote užpildyti',
  'Username already taken': 'Toks prisijungimo vardas jau egzistuoja',
  'User not found': 'Vartotojas nerastas',
  'Old password': 'Senas slaptažodis',
  'New password': 'Naujas slaptažodis',
  'Wrong password': 'Klaidingas slaptažodis',
  'Updated': 'Atnaujintas',
  'Deleted': 'Ištrintas',
  'Your file is ready': 'Jūsų failas paruoštas',
  'View': 'View',
  'Search': 'Search',
  'Download permission': 'Atsiųsti',
  'Guest': 'Guest',
  'Show hidden': 'Rodyti paslėptą',
}

export default data
