const data = {
  'Selected': 'Wybrano: {0} z {1}',
  'Uploading files': 'Przesyłanie {0}% z {1}',
  'Błąd rozmiaru pliku': '{0} jest za duży, prześlij mniejszy plik {1}',
  'Upload failed': '{0} plików nie udało się przesłać',
  'Per page': '{0} Na stronę',
  'Folder': 'Folder',
  'Login failed, please try again': 'Zły login lub hasło.',
  'Already logged in': 'Already logged in.',
  'Please enter username and password': 'Wpisz login i hasło.',
  'Not Found': 'Nie znaleziono',
  'Not Allowed': 'Nie dozwolony',
  'Please log in': 'Proszę się zalogować',
  'Unknown error': 'Nieznany błąd',
  'Add files': 'Dodaj plik',
  'New': 'Nowy',
  'New name': 'Nowa nazwa',
  'Username': 'Login',
  'Password': 'Hasło',
  'Login': 'Zaloguj',
  'Logout': 'Wyloguj',
  'Profile': 'Profile',
  'No pagination': 'Brak podziału na strony',
  'Time': 'Czas',
  'Name': 'Nazwa',
  'Size': 'Rozmiar',
  'Home': 'Folder główny',
  'Copy': 'Kopiuj',
  'Move': 'Przenieś',
  'Rename': 'Zmień nazwę',
  'Required': 'Proszę wypełnić to pole',
  'Zip': 'Zip',
  'Batch Download': 'Pobieranie zbiorcze',
  'Unzip': 'Rozpakuj',
  'Delete': 'Usuń',
  'Download': 'Download',
  'Copy link': 'Kopiuj link',
  'Done': 'Done',
  'File': 'Plik',
  'Drop files to upload': 'Upuść pliki do przesłania',
  'Close': 'Zamknij',
  'Select Folder': 'Wybierz katalog',
  'Users': 'Użytkownik',
  'Files': 'Pliki',
  'Role': 'Role',
  'Cancel': 'Anuluj',
  'Paused': 'Pauza',
  'Confirm': 'Potwierdź',
  'Create': 'Stwórz',
  'User': 'Użytkownik',
  'Admin': 'Admin',
  'Save': 'Zapisz',
  'Read': 'Podgląd',
  'Write': 'Zapisz',
  'Upload': 'Upload',
  'Permissions': 'Uprawnienia',
  'Homedir': 'Folder Główny',
  'Leave blank for no change': 'Pozostaw puste, bez zmian',
  'Are you sure you want to do this?': 'Jesteś pewny że chcesz to zrobić?',
  'Are you sure you want to allow access to everyone?': 'Czy na pewno chcesz zezwolić na dostęp wszystkim?',
  'Are you sure you want to stop all uploads?': 'Czy na pewno chcesz zatrzymać wszystkie przesyłane pliki?',
  'Something went wrong': 'Coś poszło nie tak',
  'Invalid directory': 'Nieprawidłowy katalog',
  'This field is required': 'To pole jest wymagane',
  'Username already taken': 'Nazwa użytkownika zajęta',
  'User not found': 'Użytkownik nie znaleziony',
  'Old password': 'Stare hasło',
  'New password': 'Nowe hasło',
  'Wrong password': 'Nieprawidłowe hasło',
  'Updated': 'Zaktualizowano',
  'Deleted': 'Usunięte',
  'Your file is ready': 'Twój plik jest gotowy',
  'View': 'Podgląd',
  'Search': 'Szukaj',
  'Download permission': 'Download',
  'Guest': 'Gość',
  'Show hidden': 'Pokaż ukryte',
}

export default data
